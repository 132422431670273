const Data = {
  WebsiteTitle: "Portfolio | Artists",
  HeaderTitle: "Ordinals Portfolio",
  FooterText: "© 2024  All rights reserved.",
  AboutEmail: "Your Email Address",
  AboutEmailSubject: "Subject of the Email",
  AboutHeading: "About Me",
  AboutTextParagraph1:
    "Hello! I'm Miovrag, a self-taught artist passionate about drawing. My work primarily focuses on brutalism, featuring women often depicted in erotic themes, cars, and post-apocalyptic worlds. As a colorblind artist, I enjoy playing with colors in unique ways, creating unexpected contrasts and harmonies. I'm also drawn to street art, underground culture, and retro moments, drawing inspiration from the neon noir aesthetics of certain sci-fi films and the epic visuals found in fantasy genres. I explore various techniques and styles, creating art that reflects my creativity and emotions."
,
  AboutTextParagraph2:"I believe art has the power to connect people, and through my drawings, I aim to provoke thought and evoke feelings. I'm always open to new experiences and challenges, and I look forward to each new project.",
  AboutButtonText: "Thank you for visiting my site, and I hope you enjoy my artwork!",
};

export default Data;
