import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import InscriptionCSS from "../css/Inscription.module.css";


function InscriptionPage() {
  const inscriptionUrl = "https://ordinals.com/inscription/1c0794bf18a88f198138e949271f805ada4a0c8c6e75d71e5fb06d82a2558091i0";

  return (
    <div className={InscriptionCSS.container}>
      <Header />
      <div className={InscriptionCSS.iframeWrapper}>
        <iframe
          src={inscriptionUrl}
          title="Inscription"
          className={InscriptionCSS.iframe}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <Footer />
    </div>
  );
}

export default InscriptionPage;
