import React from "react";
import UtilityCSS from "../css/Utility.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Utility() {
  return (
    <div className={UtilityCSS.container}>
      <Header />
      <div className={UtilityCSS.content}>
        <div className={UtilityCSS.textWrapper}>
          <h1>Utility and Benefits of Holding Your Inscription</h1>
          <p>
            Our unique offering grants exclusive benefits to holders of the artist’s inscription. By
            holding onto this inscription for a designated period, you unlock exclusive access to 
            original, physical artwork. This is more than just a digital possession—your commitment 
            signifies a connection to the artist’s creative journey, and the reward is a tangible piece 
            of art, crafted with care.
          </p>
          <p>
            As a dedicated holder, you’ll receive:
          </p>
          <ul>
            <li>A one-of-a-kind physical original of the artwork.</li>
            <li>Invitations to exclusive exhibitions and events.</li>
            <li>Access to limited-edition art releases and early previews.</li>
            <li>Priority for future projects and unique collectibles.</li>
          </ul>
          <p>
            This program is a testament to the artist’s commitment to fostering a closer bond with the 
            community. Holding your inscription ensures that you’re not just an observer but an integral 
            part of this artistic journey.
          </p>
          <p>
            Join us in redefining the art experience. Hold your inscription and witness the rewards of 
            patience and commitment unfold.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Utility;
